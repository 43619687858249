import React from "react"
import { Player } from "@lottiefiles/react-lottie-player"
import Section from "../../../components/section/Section"
import img404 from "../../../assets/404/404.svg"
import RobotAnim from "../../../assets/animation/404Robot.json"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  img404: img404,
}

const Page404SectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { text, img404Src, img404Alt, btnToMainPageText, btnToMainPageLink } = data.data.content.Page404FirstSectionConfig

  return (
    <Section className={`${className} sova-section-without-margin-bottom sova-fullscreen-height`}>
      <div className="sova-section-container sova-section-container_row">
        <div className="sova-section-child-container sova-section-child-container_page404-page404SectionFirst">
          <img src={images[img404Src]} alt={img404Alt} className="sova-section-child sova-section-img" />
          <p className="sova-section-child sova-h3">{text}</p>
          <a href={btnToMainPageLink} className="sova-section-child sova-section-child_page404-page404SectionFirst-btn">
            <button className="sova-btn sova-btn_primary sova-btn_big">{btnToMainPageText}</button>
          </a>
        </div>
        <div className="sova-section-child sova-section-img sova-section-img_page404-page404SectionFirst">
          <Player autoplay loop src={RobotAnim} />
        </div>
      </div>
    </Section>
  )
}

Page404SectionFirst.displayName = "Page404SectionFirst"

export default Page404SectionFirst
