import { LayoutProps } from "../../layout/layout"

export const layoutConfig404: LayoutProps = {
  bgEnabled: false,
  footerEnabled: false,
  mcCustomClass: "fullscreen",
  mCustomClass: "404",
}

export const layoutConfigPazzlelib: LayoutProps = {
  pageName: "Puzzlelib",
}
