import React from "react"

import Page404SectionFirst from "./page404Sections/page404SectionFirst"

const sections = [{ component: (key: number, className: string, data: any) => <Page404SectionFirst data={data} className={className} key={key} /> }]

const Page404Sections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-page404  sova-section-page404_${i}`, data))}</>
}

Page404Sections.displayName = "Page404Sections"

export default Page404Sections
