import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO/seo"
import Layout from "../layout/layout"
import { layoutConfig404 } from "../configs/layout/layoutConfig"
import Page404Sections from "../pagesStructures/page404/page404Sections"

const NotFoundPage = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiNotFound.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout {...layoutConfig404} bgCustomClass="404">
      <SEO title={pageContent.content.title} />
      <Page404Sections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFound($url: String) {
    strapiNotFound(url: { eq: $url }) {
      content {
        content {
          title
          Page404FirstSectionConfig {
            btnToMainPageLink
            btnToMainPageText
            decorationImgAlt
            decorationImgSrc
            img404Alt
            img404Src
            text
          }
        }
        language
      }
    }
  }
`
